import React from 'react'

import { Banner } from './banner.atom'

import Bg from '../../../images/xmas/bg.png'
import BgMobile from '../../../images/xmas/bg-mobile.png'
import CHRISTMASDAY from '../../../images/xmas/CHRISTMASDAY.png'
import Text from '../../../images/xmas/text.png'
import Tag from '../../../images/xmas/tag.png'

export default function XmasBanner({ inSwiper = false }) {
  const onClick = () => {
    if (inSwiper) {
      window.location.pathname = '/xgt-xmas.html'
      return
    }
    window.open('https://account.renderbus.com/center/user/topUp', '_blank')
  }
  return (
    <Banner className={[inSwiper && 'inSwiper']}>
      <picture>
        <source srcSet={Bg} media='(min-width: 600px)' />
        <source srcSet={BgMobile} media='(max-width: 600px)' />
        <img className='banner-bg' src={Bg} alt='xmas' />
      </picture>
      <img className='xmas-activity-tag' src={Tag} alt='效果图专享' />
      <div className='main-container'>
        <img className='christmas-day' src={CHRISTMASDAY} alt='CHRISTMASDAY' />
        <img className='banner-text' src={Text} alt='跨年送会员' />

        <div className='xmas-activity'>
          「充值」最高送12个月会员+500渲染券，「实名」立送1个月会员
        </div>
        <div className='xmas-button' id='xgt-xmas' onClick={onClick}>
          {inSwiper ? '点击查看' : '立即充值'}
        </div>
        <div className='xmas-activity-time'>活动时间：2021.12.20—2022.01.10 </div>
      </div>
    </Banner>
  )
}
