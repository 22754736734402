import styled from 'styled-components'

import Media from '@renderbus/common/theme/media'

import CardBg from '../images/xmas/card-bg.png'
import CardBgMoible from '../images/xmas/card-bg-mobile.png'

import Arrow from '../images/xmas/arrow.png'
import ArrowHover from '../images/xmas/arrow-hover.png'

export const XmasContainer = styled.div`
  margin-top: 60px;
`

export const XmasContent = styled.div`
  overflow: hidden;
  background-color: #09412b;
  .xmas-title-img {
    display: block;
    width: 472px;
    margin: 0 auto;
    &.t1 {
      margin-top: 90px;
    }
    &.t2 {
      margin-top: 105px;
    }
    &.t3 {
      margin-top: 140px;
    }
    ${Media.lessThan(Media.small)} {
      width: 280px;
      &.t1,
      &.t2,
      &.t3 {
        margin-top: 45px;
      }
    }
  }
  .xmas-content {
    display: flex;
    justify-content: center;
    &.c1 {
      margin-top: 70px;
    }
    &.c2 {
      margin-top: 80px;
    }
    &.c3 {
      margin-top: 60px;
      margin-bottom: 120px;
    }
    ${Media.lessThan(Media.small)} {
      &.c1 {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        padding: 0 15px;
      }
      &.c2 {
        margin-top: 35px;
      }
      &.c3 {
        margin-top: 40px;
        margin-bottom: 55px;
      }
    }
  }

  .xmas-gold-card {
    position: relative;
    width: 336px;
    height: 170px;
    padding: 0 20px;
    color: #105637;
    background: url(${CardBg}) center / auto 170px;
    & + .xmas-gold-card {
      margin-left: 18px;
    }
    ${Media.lessThan(Media.small)} {
      width: 100%;
      height: 75px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      background: url(${CardBgMoible}) center / 100% 75px;
      & + .xmas-gold-card {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .xmas-price {
      position: relative;
      font-size: 48px;
      font-weight: bold;
      margin-left: 20px;
      &::before {
        position: absolute;
        bottom: 10px;
        margin-left: -20px;
        content: '￥';
        font-size: 24px;
      }
      ${Media.lessThan(Media.small)} {
        width: 75px;
        font-size: 30px;
        margin-left: 15px;
        white-space: nowrap;
        &::before {
          font-size: 18px;
          bottom: 5px;
          margin-left: -15px;
        }
      }
    }

    .xmas-discount {
      font-size: 18px;
      font-weight: 500;
      margin-top: -10px;
      ${Media.lessThan(Media.small)} {
        width: 90px;
        font-size: 15px;
        margin-left: 12px;
        margin-top: 0;
        span {
          display: block;
          white-space: nowrap;
        }
      }
    }

    .xmas-detail {
      font-size: 12px;
      ${Media.lessThan(Media.small)} {
        display: none;
      }
    }

    .xmas-button {
      position: absolute;
      width: 120px;
      padding: 0 15px;
      line-height: 30px;
      font-size: 16px;
      border-radius: 15px;
      border: 1px solid #09412b;
      bottom: 12px;
      cursor: pointer;
      background: url(${Arrow}) no-repeat 90px 10px / auto 10px;
      transition: background-color 300ms, color 300ms;
      &:hover {
        background-color: #09412b;
        background-image: url(${ArrowHover});
        color: #fff;
      }

      ${Media.lessThan(Media.small)} {
        right: 15px;
        bottom: 20px;
        width: 110px;
      }
    }
  }

  .green-card-container {
    img {
      height: 300px;
    }

    picture + picture {
      margin-left: 30px;
    }
    ${Media.lessThan(Media.small)} {
      flex-direction: column;
      align-items: center;
      img {
        height: 200px;
      }
      picture + picture {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  .activity-rules-container {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    max-width: 1370px;
    color: #fff;
    ${Media.lessThan(Media.small)} {
      font-size: 15px;
      line-height: 1.5;
      max-width: auto;
      padding: 0 15px;
    }
  }
`
