import styled from 'styled-components'

import Media from '@renderbus/common/theme/media'

export const Banner = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;
  ${Media.lessThan(Media.small)} {
    height: 580px;
  }
  &.inSwiper {
    margin-top: 60px;
    .main-container {
      margin-top: -50px;
      ${Media.lessThan(Media.small)} {
        margin-top: 0;
      }
    }
  }
  img {
    width: 100%;
    user-select: none;
  }
  .main-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    ${Media.lessThan(Media.small)} {
      top: 0;
      transform: none;
    }
  }
  .banner-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: -1;
    ${Media.lessThan(Media.small)} {
      height: 580px;
    }
  }
  .christmas-day {
    display: block;
    width: auto;
    height: 19px;
    margin: 0 auto;
    left: 0;
    right: 0;
    ${Media.lessThan(Media.small)} {
      display: none;
    }
  }
  .xmas-activity-tag {
    position: absolute;
    top: 82px;
    left: 0;
    width: 160px;
    ${Media.lessThan(Media.small)} {
      top: 40px;
      width: 80px;
    }
  }
  .banner-text {
    display: block;
    width: auto;
    height: 150px;
    margin: 0 auto;
    margin-top: 50px;
    ${Media.lessThan(Media.small)} {
      top: 0;
      width: 350px;
      height: 60px;
      margin-top: 165px;
    }
  }
  .xmas-activity {
    margin-top: 35px;
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #f7c58f;
    ${Media.lessThan(Media.small)} {
      font-size: 18px;
      width: 290px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 18px;
    }
  }
  .xmas-button {
    width: 200px;
    margin: 26px auto 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
    color: #105637;
    background: linear-gradient(to left, #ffae66, #f7c894);
    border-radius: 25px;
    cursor: pointer;
    ${Media.lessThan(Media.small)} {
      width: 150px;
      line-height: 35px;
      font-size: 18px;
    }
  }
  .xmas-activity-time {
    margin-top: 30px;
    text-align: center;
    color: #fff;
    ${Media.lessThan(Media.small)} {
      margin-top: 20px;
      font-size: 12px;
    }
  }
`
